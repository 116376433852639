exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-free-trial-index-jsx": () => import("./../../../src/pages/free-trial/index.jsx" /* webpackChunkName: "component---src-pages-free-trial-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-discounts-jsx": () => import("./../../../src/pages/info/discounts.jsx" /* webpackChunkName: "component---src-pages-info-discounts-jsx" */),
  "component---src-pages-info-privacy-jsx": () => import("./../../../src/pages/info/privacy.jsx" /* webpackChunkName: "component---src-pages-info-privacy-jsx" */),
  "component---src-pages-info-samples-jsx": () => import("./../../../src/pages/info/samples.jsx" /* webpackChunkName: "component---src-pages-info-samples-jsx" */),
  "component---src-pages-info-services-jsx": () => import("./../../../src/pages/info/services.jsx" /* webpackChunkName: "component---src-pages-info-services-jsx" */),
  "component---src-pages-info-terms-jsx": () => import("./../../../src/pages/info/terms.jsx" /* webpackChunkName: "component---src-pages-info-terms-jsx" */),
  "component---src-pages-order-quote-jsx": () => import("./../../../src/pages/order/quote.jsx" /* webpackChunkName: "component---src-pages-order-quote-jsx" */),
  "component---src-pages-support-faq-js": () => import("./../../../src/pages/support/faq.js" /* webpackChunkName: "component---src-pages-support-faq-js" */),
  "component---src-pages-testimonials-index-jsx": () => import("./../../../src/pages/testimonials/index.jsx" /* webpackChunkName: "component---src-pages-testimonials-index-jsx" */),
  "component---src-templates-landing-list-js": () => import("./../../../src/templates/landing-list.js" /* webpackChunkName: "component---src-templates-landing-list-js" */),
  "component---src-templates-landing-post-js": () => import("./../../../src/templates/landing-post.js" /* webpackChunkName: "component---src-templates-landing-post-js" */)
}

